import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CustomLink from './custom-link'
import Grid from '@material-ui/core/Grid'
import Img from 'gatsby-image'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '42px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '32px'
    },
    '& h3': {
      marginTop: '20px',
      [theme.breakpoints.down('xs')]: {
        marginTop: '0px'
      }
    }
  },
  testimonial: {
    backgroundColor: 'rgba(131, 111, 80, 0.24)',
    padding: '32px'
  }
}))

const ADMCertigfication = ({ images }) => {
  const classes = useStyles()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'))
  return (
    <Box className={classes.root}>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8}>
            <Box maxWidth="460px">
              <Typography variant="h3" color="secondary" gutterBottom>
                Get ADM Certified
              </Typography>
              <Typography variant="boby1" gutterBottom>
                The American Development Model is essential for growing the
                sport and vital to you and your employer’s success.
              </Typography>
              <Box
                mt={isMobile ? 3 : 4}
                display="flex"
                justifyContent={isMobile ? 'center' : 'start'}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  component={CustomLink}
                  href="https://pgacoach.lightspeedvt.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Get Certified Today
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box className={classes.testimonial}>
              <Typography variant="body1" gutterBottom>
                "Getting your ADM certification through PGA.Coach is the
                critical first step in designing your coaching around the age
                and stage appropriate development of your players. In doing so,
                you’ll create athletes for life, and that’s a priceless asset to
                both your coaching programs and the future of the game."
              </Typography>
              <Box display="flex" alignItems="center" mt={2}>
                <Img fixed={images.Author.childImageSharp.fixed} />
                <Box ml={1}>
                  <Typography variant="subtitle2">Kevin Kirk, PGA</Typography>
                  <Typography variant="subtitle2">
                    2019 PGA Teacher of the Year
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box pt={6} pb={4}>
          <Img fluid={images.Logos.childImageSharp.fluid} />
        </Box>
      </Container>
    </Box>
  )
}

export default ADMCertigfication
