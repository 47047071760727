import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

export const useStyles = makeStyles(theme => ({
  heroWrapper: {
    width: '100%',
    maxWidth: '570px',
    position: 'absolute',
    zIndex: '100',
    top: 'calc(50% + 48px)',
    color: theme.palette.primary.contrastText,
    transform: 'translateY(-50%)',
    padding: '0 24px',
    [theme.breakpoints.up('lg')]: {
      left: 'calc(50% - 640px)'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '310px',
      textAlign: 'center',
      margin: '0 auto',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  },
  root: {
    overflow: 'hidden',
    maxHeight: '526px',
    height: '526px',
    position: 'relative'
  },
  videoWrapper: {
    position: 'relative',
    paddingBottom: '65.25%',
    height: 0,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      height: '526px'
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      left: '0px',
      top: '0px',
      width: '100%',
      height: '100%',
      background: 'rgba(15, 16, 19, 0.33)',
      zIndex: '100'
    },
    '& iframe': {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      border: '0',
      [theme.breakpoints.up('xl')]: {
        top: '-50%'
      },
      [theme.breakpoints.down('sm')]: {
        width: '935px',
        height: '526px'
      }
    }
  }
}))

const Video = ({ src }) => (
  <iframe
    src={src}
    border="0"
    webkitallowfullscreen="true"
    mozallowfullscreen="true"
    allowFullScreen
    title="PGA Coach"
  />
)

const HeroVideo = ({ title, video }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.videoWrapper}>
        <Video src={video} />
      </Box>
      <Box className={classes.heroWrapper}>
        <Typography variant="h2" gutterBottom>
          {title}
        </Typography>
      </Box>
    </Box>
  )
}

export default HeroVideo
