import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Img from 'gatsby-image'

export const useStyles = makeStyles(theme => ({
  root: () => ({
    padding: '70px 0 105px',
    background: theme.palette.blue.light100,
    backgroundSize: 'cover',
    [theme.breakpoints.down('xs')]: {
      padding: '32px 0 40px',
      '& h3': {
        textAlign: 'center',
        padding: '0 20px'
      }
    }
  }),
  link: {
    display: 'block',
    '&:hover': {
      boxShadow: '0px 4px 36px rgba(0, 10, 22, 0.13)'
    }
  }
}))

const Training = ({ images }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root} display="flex" flexDirection="column">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={7}>
            <Box maxWidth="665px">
              <Typography variant="h3" color="secondary" gutterBottom>
                Leading the Industry in Coach Training & Development
              </Typography>
              <Typography variant="body1" color="secondary" gutterBottom>
                The PGA of America is dedicated to providing training and
                education resources for professionals across the entire golf
                industry. Golf professionals have completed{' '}
                <strong>over 10,000</strong> of our courses, and are helping to
                revolutionize the industry. By applying the training, these
                professionals are realizing improved work/life balance,
                continued development of their students, and the creation of a
                positive environment for the golfers of the future.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box maxWidth="400px">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <a
                    href="https://pgaguest.lightspeedvt.com "
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    <Img fluid={images.LPGATraining.childImageSharp.fluid} />
                  </a>
                </Grid>
                <Grid item xs={6}>
                  <a
                    href="https://pgacoach.lightspeedvt.com"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    <Img
                      fluid={images.PGACoachTraining.childImageSharp.fluid}
                    />
                  </a>
                </Grid>
                <Grid item xs={6}>
                  <a
                    href="https://pgaguest.lightspeedvt.com"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    <Img fluid={images.HSCoachTraining.childImageSharp.fluid} />
                  </a>
                </Grid>
                <Grid item xs={6}>
                  <a
                    href="https://pgaguest.lightspeedvt.com"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    <Img
                      fluid={images.IndustryGuestTraining.childImageSharp.fluid}
                    />
                  </a>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Training
