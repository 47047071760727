import React from 'react'
import { LayoutV2 } from '../components/common'
import HeroVideo from '../components/hero-video'
import ADMCertigfication from '../components/adm-certification'
import ADM from '../components/adm'
import Training from '../components/training-section'
import FeaturedPrograms from '../components/featured-programs'
import { graphql } from 'gatsby'
import { JssProvider } from 'react-jss'
import { createGenerateClassName } from '@material-ui/core/styles'

const TrainingsPage = props => {
  const classPrefix = 'index-new-page'
  const { LogoADM } = props.data
  const generateClassName = createGenerateClassName({
    productionPrefix: classPrefix
  })
  return (
    <JssProvider generateClassName={generateClassName}>
      <LayoutV2 title="PGA.Coach New">
        <HeroVideo
          title="Preparing and Empowering Coaches"
          video="https://player.vimeo.com/video/318557649?background=1&autoplay=1&loop=1&byline=0&title=0"
        />
        <ADMCertigfication images={props.data} />
        <ADM image={LogoADM} />
        <Training images={props.data} />
        <FeaturedPrograms images={props.data} />
      </LayoutV2>
    </JssProvider>
  )
}

export const query = graphql`
  query TrainingQuery {
    Logos: file(relativePath: { eq: "logos.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    LogoADM: file(relativePath: { eq: "ADM_Logo.png" }) {
      childImageSharp {
        fixed(width: 305, height: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    Standard: file(relativePath: { eq: "Standard.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Standard1: file(relativePath: { eq: "Standard-1.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Standard2: file(relativePath: { eq: "Standard-2.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    GolfLogos: file(relativePath: { eq: "golf-logos.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HSCoachTraining: file(relativePath: { eq: "HS Coach Training.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    LPGATraining: file(relativePath: { eq: "LPGA Training.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    PGACoachTraining: file(relativePath: { eq: "PGA Coach Training.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    IndustryGuestTraining: file(
      relativePath: { eq: "Industry Guest Training.png" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Author: file(relativePath: { eq: "Kevin_Kirk.png" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default TrainingsPage
