import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Img from 'gatsby-image'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import { IconArrow } from './icons'

export const useStyles = makeStyles(theme => ({
  root: () => ({
    padding: '32px 0 48px',
    backgroundSize: 'cover',
    [theme.breakpoints.down('xs')]: {
      padding: '32px 0 40px',
      '& h3': {
        textAlign: 'center',
        padding: '0 40px'
      }
    }
  }),
  card: {
    background: theme.palette.common.white,
    borderRadius: '8px',
    boxShadow: theme.palette.shadow.cardBoxShadow,
    border: 'none',
    marginBottom: '20px',
    '& a': {
      textDecoration: 'none',
      height: '100%',
      color: 'inherit'
    },
    '&:hover': {
      boxShadow: '0px 4px 36px rgba(0, 10, 22, 0.28)',
      '& a': {
        textDecoration: 'none !important',
        color: 'inherit'
      }
    }
  },
  gridItem: {
    display: 'flex'
  },
  button: {
    width: '3.0625rem',
    height: '3.0625rem',
    display: 'flex',
    padding: 0,
    minWidth: 'initial',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    color: '#3268A7',
    position: 'absolute',
    bottom: '-25px',
    left: '50%',
    border: 'none',
    zIndex: '100',
    boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.18)',
    '&:hover': {
      color: '#F7F7F7',
      backgroundColor: '#001529'
    },
    '&:focus': {
      border: '3px solid white',
      outline: '3px solid black'
    }
  },
  prevButton: {
    left: '50%',
    transform: 'translateX(-3.0625rem) rotate(180deg)'
  }
}))

const PrevButton = props => {
  const { onClick } = props
  const classes = useStyles()
  return (
    <button
      className={`${classes.button} ${classes.prevButton}`}
      onClick={onClick}
    >
      <IconArrow />
    </button>
  )
}

const NextButton = props => {
  const { onClick } = props
  const classes = useStyles()
  return (
    <button className={classes.button} onClick={onClick}>
      <IconArrow />
    </button>
  )
}

const FeaturedPrograms = ({ images }) => {
  const classes = useStyles()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'))
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextButton />,
    prevArrow: <PrevButton />,
    adaptiveHeight: true
  }
  const featuredPrograms = [
    {
      title: 'Golf’s American Development Model',
      description:
        'Developing fundamental skills for golf by providing a "sport pathway" for coaches and athletes to get involved and become passionate about sports for their lifetime.',
      image: images.Standard.childImageSharp.fluid,
      url: 'https://pgacoach.lightspeedvt.com/'
    },
    {
      title: 'Modern PGA Coach',
      description:
        'Led by PGA Member Will Robins, this course teaches you how to increase your value to players and facilities, build relationships and connections, and create solutions that provide results, all while developing discipline, commitment, and never-ending improvement.',
      image: images.Standard1.childImageSharp.fluid,
      url: 'https://pgacoach.lightspeedvt.com/'
    },
    {
      title: 'Characteristics of the “Ideal Golf Coach”',
      description:
        'Featuring world-class coaches aimed at helping you grow the game, your business and yourself, the training is divided into four courses that can be watched at your own pace.',
      image: images.Standard2.childImageSharp.fluid,
      url: 'https://pgacoach.lightspeedvt.com/'
    }
  ]
  return (
    <Box className={classes.root} display="flex" flexDirection="column">
      <Container maxWidth="xl">
        <Box
          display="flex"
          justifyContent="center"
          mb={isMobile ? 0 : 3}
          textAlign="center"
        >
          <Typography
            variant="h4"
            color="secondary"
            gutterBottom
            textAlign="center"
          >
            Featured Coach Training Programs
          </Typography>
        </Box>
      </Container>
      {isMobile ? (
        <Box mb={3}>
          <Slider {...settings}>
            {featuredPrograms.map((item, index) => (
              <Box py={3} px={2}>
                <Card className={classes.card} variant="outlined">
                  <a href={item.url} target="_blank" rel="noreferrer">
                    <Img fluid={item.image} />
                    <CardContent>
                      <Box p={2}>
                        <Typography
                          variant="subtitle1"
                          component="h2"
                          gutterBottom
                        >
                          {item.title}
                        </Typography>
                        <Typography variant="body2" component="p">
                          {item.description}
                        </Typography>
                      </Box>
                    </CardContent>
                  </a>
                </Card>
              </Box>
            ))}
          </Slider>
        </Box>
      ) : (
        <Container maxWidth="xl">
          <Grid container spacing={4}>
            {featuredPrograms.map((item, index) => (
              <Grid item xs={12} md={4} className={classes.gridItem}>
                <Card className={classes.card} variant="outlined">
                  <a href={item.url} target="_blank" rel="noreferrer">
                    <Img fluid={item.image} />
                    <CardContent>
                      <Box p={2}>
                        <Typography
                          variant="subtitle1"
                          component="h2"
                          gutterBottom
                        >
                          {item.title}
                        </Typography>
                        <Typography variant="body2" component="p">
                          {item.description}
                        </Typography>
                      </Box>
                    </CardContent>
                  </a>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      )}
    </Box>
  )
}

export default FeaturedPrograms
