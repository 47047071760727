import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Img from 'gatsby-image'
import CustomLink from './custom-link'
import { IconButtonCtaArrow } from './icons'

export const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px 0 48px',
    [theme.breakpoints.down('xs')]: {
      padding: '24px 0 48px'
    },
    '& h3': {
      marginBottom: '60px',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '24px'
      }
    }
  },
  ctaButton: {
    paddingLeft: '0',
    paddingRight: '0',
    [theme.breakpoints.down('sm')]: {
      display: 'flex'
    }
  }
}))

export default function ADM({ image }) {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Box>
              <Typography
                variant="h5"
                color="secondary"
                style={{ marginBottom: '16px' }}
              >
                What is ADM?
              </Typography>
              <Typography variant="body1">
                Golf’s new pathway is built on the principles of the American
                Development Model (ADM), a proven, long-term athlete development
                model that has been successfully applied to USA Hockey, USOC,
                USA Basketball and USA Lacrosse.
              </Typography>
              <Button
                variant="text"
                color="secondary"
                style={{ marginTop: '16px' }}
                endIcon={<IconButtonCtaArrow />}
                className={classes.ctaButton}
                component={CustomLink}
                href="https://pga.coach/Golf's_American_Development_Model.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Learn more about ADM
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <Typography
                variant="h5"
                color="secondary"
                style={{ marginBottom: '16px' }}
              >
                How Does ADM Work?
              </Typography>
              <Typography variant="body1">
                Creating positive experiences early, for all athletes, will keep
                more players engaged and retained in our sport. Introducing the
                right aspects of play in a structure that is fun, engaging, and
                progressively challenging, allows golfers of all ages to
                experience the challenges and joys of our sport.
              </Typography>
              <Button
                variant="text"
                color="secondary"
                style={{ marginTop: '16px' }}
                endIcon={<IconButtonCtaArrow />}
                className={classes.ctaButton}
                component={CustomLink}
                href="/coach-parents"
              >
                Access parent resources
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <Typography
                variant="h5"
                color="secondary"
                style={{ marginBottom: '16px' }}
              >
                Why Does ADM Work?
              </Typography>
              <Typography variant="body1">
                ADM was designed by sports scientists to help support a lifetime
                affinity to sports, and to develop athletes to their greatest
                potential. These principles align physical and psychological
                development to stages, delivering appropriate skills and
                exercise at the appropriate time.
              </Typography>
              <Button
                variant="text"
                color="secondary"
                style={{ marginTop: '16px' }}
                endIcon={<IconButtonCtaArrow />}
                className={classes.ctaButton}
                component={CustomLink}
                href="https://pga.coach/Golf's_American_Development_Model.pdf?_ga=2.26598965.92732300.1705528617-2091716491.1681245381"
              >
                Learn about ADM stages
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="center" mt={8}>
          <Img fixed={image.childImageSharp.fixed} />
        </Box>
      </Container>
    </Box>
  )
}
